import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams, Link } from 'react-router-dom';
import { jsPDF } from "jspdf"
import html2canvas from 'html2canvas'

const TicketMaqueta = () => {
  let { id } = useParams();
  const [ticketData, setTicketData] = useState(null);
  const [rq, setQr] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [width, setWidth] = useState(0)
  const [height, setHeight] = useState(0)
  useEffect(() => {
    const fetchTicketData = async () => {
      try {
        const response = await axios.get(`https://api.calzzapato.com/api/orders/ticket/${id}`);
        setTicketData(response.data);

        let qrResponse = await axios.get(`https://api.calzzapato.com/api/orders/ticket/qr/${id}`);

        if (qrResponse.status === 200) {
          if (qrResponse.data) {
            console.log(qrResponse.data.message)
            let imagebase64 = await downloadImageToBase64(`https://api.calzzapato.com${qrResponse.data.message.qrImage}`)
            console.log(imagebase64)
            setQr(imagebase64)
          }
        }
      } catch (err) {
        setError('Error al cargar los datos del ticket');
      } finally {
        setLoading(false);
      }
    };

    fetchTicketData();
  }, []);

  useEffect(() => {
    let ticket = document.getElementById("ticket")
    if (ticket) {
      if (ticket.offsetWidth !== width || ticket.offsetHeight !== height) {
        let width = ticket.offsetWidth
        let height = ticket.offsetHeight

        setWidth(width)
        setHeight(height)
      }
    }
  }, [width, height])

  const download = () => {
    let imgWidth = 210;
    let pageHeight = 295;
    let ticket = document.getElementById("ticket")

    if (ticket) {
      if (ticket.offsetWidth !== width || ticket.offsetHeight !== height) {
        imgWidth = ticket.offsetWidth
        pageHeight = ticket.offsetHeight
      }
    }
    html2canvas(ticket).then((canvas) => {
      const imgData = canvas.toDataURL("image/png")
      const pdf = new jsPDF("p", "px", [imgWidth * 1.2, pageHeight * 1.2])
      pdf.addImage(imgData, "PNG", 0, 0)
      pdf.save("ticket.pdf")
    })
  }

  async function downloadImageToBase64(url) {
    try {
      const response = await fetch(url); // Realiza la solicitud HTTP para obtener la imagen
      const blob = await response.blob(); // Convierte la respuesta en un objeto Blob (representación de la imagen)

      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result); // Convierte el Blob en Base64
        reader.onerror = reject;
        reader.readAsDataURL(blob); // Lee el Blob como una URL en base64
      });
    } catch (error) {
      return null;
    }
  }

  const numberWithCommas = (x) => {
    return x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
  }
  if (loading) return <p>Cargando...</p>;
  if (error) return <p>{error}</p>;

  // Extrayendo los datos que provienen del API
  const { emisor, emisorSucursal, encabezado, detalle } = ticketData;

  return (
    <div style={{ background: "#edeef2" }}>
      <div id="ticket" style={{ maxWidth: '400px', margin: '0 auto', padding: '20px 20px 0px 20px', background: '#FFFFFF', fontFamily: 'Arial, sans-serif' }}>
        <h2 style={{ fontWeight: 400, fontSize: 16, color: "#353B48", textAlign: "center", paddingBottom: 15 }}>Ticket digital</h2>

        <div style={{ display: "flex", flexWrap: "wrap" }}>
          <strong style={{ fontSize: 14, textAlign: "left", fontWeight: 600, lineHeight: 1, width: "50%" }}>Sucursal:</strong>
          <div style={{ fontSize: 14, textAlign: "right", lineHeight: 1, width: "50%" }}>{emisorSucursal[0].emisorSucursal}</div>

          <strong style={{ fontSize: 14, textAlign: "left", fontWeight: 600, lineHeight: 1, width: "50%" }}>Fecha:</strong>
          <div style={{ fontSize: 14, textAlign: "right", lineHeight: 1, width: "50%" }}>{encabezado[0].fecha}</div>

          <strong style={{ fontSize: 14, textAlign: "left", fontWeight: 600, lineHeight: 1, width: "50%" }}>Transacción:</strong>
          <div style={{ fontSize: 14, textAlign: "right", lineHeight: 1, width: "50%" }}>{encabezado[0].folioFactura}</div>
        </div>

        <div style={{ borderBottom: '1px solid #ddd', paddingBottom: '10px', paddingTop: '10px' }}></div>

        <div>
          {detalle.map((item, index) => (
            <p key={index} style={{ width: "100%", display: "flex", flexDirection: "row" }}>
              <div style={{ width: "15%", fontSize: 14 }}>{item.vendedor.substring((item.vendedor.length - 4), item.vendedor.length)}</div>
              <div style={{ width: "55%", fontSize: 14 }}>{item.descripcion}</div>
              <div style={{ width: "10%", textAlign: "center", fontSize: 14 }}>{item.cantidad}</div>
              <div style={{ width: "20%", fontSize: 14, textAlign: "right" }}>${item.precioAplicado.toFixed(2)}</div>
            </p>
          ))}
        </div>

        <div style={{ display: "flex", flexWrap: "wrap" }}>
          <strong style={{ fontSize: 14, textAlign: "left", fontWeight: 600, lineHeight: 1, width: "50%" }}>Total de Artículos:</strong>
          <div style={{ fontSize: 14, textAlign: "right", lineHeight: 1, width: "50%" }}>{detalle.length}</div>

          <strong style={{ fontSize: 14, textAlign: "left", fontWeight: 600, lineHeight: 1, width: "50%" }}>Subtotal:</strong>
          <div style={{ fontSize: 14, textAlign: "right", lineHeight: 1, width: "50%" }}>${numberWithCommas(encabezado[0].ImporteSubTotal.toFixed(2))}</div>

          <strong style={{ fontSize: 14, textAlign: "left", fontWeight: 600, lineHeight: 1, width: "50%" }}>Bonificación:</strong>
          <div style={{ fontSize: 14, textAlign: "right", lineHeight: 1, width: "50%" }}>{encabezado[0].importeDescuento.toFixed(2)}</div>

          <strong style={{ fontSize: 14, textAlign: "left", fontWeight: 600, lineHeight: 1, width: "50%" }}>Impuesto:</strong>
          <div style={{ fontSize: 14, textAlign: "right", lineHeight: 1, width: "50%" }}>${numberWithCommas(encabezado[0].importeImpuesto.toFixed(2))}</div>

          <strong style={{ fontSize: 14, textAlign: "left", fontWeight: 600, lineHeight: 1, width: "50%" }}>Total:</strong>
          <div style={{ fontSize: 14, textAlign: "right", lineHeight: 1, width: "50%" }}>{numberWithCommas(encabezado[0].importeVenta.toFixed(2))}</div>
        </div>

        <div style={{ width: "100%", fontSize: 14, paddingTop: 20 }}>{encabezado[0].importeenLetra}</div>

        <div style={{ display: "flex", flexWrap: "wrap", paddingTop: 15 }}>
          <strong style={{ fontSize: 14, textAlign: "left", fontWeight: 600, lineHeight: 1, width: "50%" }}>Cajero:</strong>
          <div style={{ fontSize: 14, textAlign: "left", lineHeight: 1, width: "50%" }}>{encabezado[0].cajero}</div>

          <strong style={{ fontSize: 14, textAlign: "left", fontWeight: 600, lineHeight: 1, width: "50%" }}>Venta:</strong>
          <div style={{ fontSize: 14, textAlign: "left", lineHeight: 1, width: "50%" }}>{encabezado[0].clavePago}</div>

          <strong style={{ fontSize: 14, textAlign: "left", fontWeight: 600, lineHeight: 1, width: "50%" }}>Forma de pago:</strong>
          <div style={{ fontSize: 14, textAlign: "left", lineHeight: 1, width: "50%" }}>{encabezado[0].metodoPago}</div>

          <strong style={{ fontSize: 14, textAlign: "left", fontWeight: 600, lineHeight: 1, width: "50%" }}>Cuenta de pago:</strong>
          <div style={{ fontSize: 14, textAlign: "left", lineHeight: 1, width: "50%" }}>{encabezado[0].cuentaPago}</div>
        </div>

        <div style={{ borderBottom: '1px solid #ddd', paddingBottom: '10px', paddingTop: '10px' }}></div>

        <h3 style={{ textAlign: "center", fontSize: 14 }}>Cliente</h3>
        <div style={{ borderBottom: '1px solid #ddd', paddingBottom: '10px', fontSize: 14 }}>
          <p>{encabezado[0].nombreCliente}</p>
        </div>

        <h3 style={{ textAlign: "center", fontSize: 14 }}>Factura electrónica</h3>

        <div style={{ width: "100%", fontSize: 14 }}>{emisor[0].razonSocial}</div>
        <div style={{ width: "100%", fontSize: 14 }}>{emisor[0].calle + " " + emisor[0].numeroExterior}</div>
        <div style={{ width: "100%", fontSize: 14 }}>{emisor[0].colonia}</div>
        <div style={{ width: "100%", fontSize: 14 }}>{emisor[0].municipio +
          ", " +
          emisor[0].estado +
          ", " +
          emisor[0].pais}</div>
        <div style={{ width: "100%", fontSize: 14 }}>{emisor[0].rfc}</div>
        <div style={{ width: "100%", fontSize: 14 }}>RÉGIMEN FISCAL: {emisor[0].regimenFiscal}</div>

        <div style={{ textAlign: 'center' }}>
          <img style={{ maxWidth: 200 }} src={rq}></img>
        </div>

      </div>
      <div id="ticket" style={{ maxWidth: '400px', margin: '0px auto', padding: '10px 20px 20px 20px', background: '#FFFFFF', fontFamily: 'Arial, sans-serif' }}>
        <div id="botonPoliza" style={{ textAlign: 'center' }}>
          <Link to={"/poliza-garantia"}>
            <p style={{ fontSize: '12px', color: '#007bff', cursor: 'pointer' }}>Póliza de garantía</p>
          </Link>
          <button id="botonDescargar" onClick={() => download()} style={{ display: 'block', width: '100%', padding: '10px', backgroundColor: '#007bff', color: '#fff', border: 'none', borderRadius: '5px', cursor: 'pointer' }}>
            Descargar PDF
          </button>
        </div>
      </div>
    </div>
  );
};

export default TicketMaqueta;
