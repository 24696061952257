import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import ThemeProviderContext from './theme/ThemeProvider';
import SnackbarContext from './context/SnackbarContext';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <ThemeProviderContext>
      <SnackbarContext>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </SnackbarContext>
    </ThemeProviderContext>
  </React.StrictMode>
);

